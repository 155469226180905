import currencies from './currencies'
import translators from './translators'
const currencyOptions = currencies.map(x => x.code)

const taxCoverage = [
  'Aviation and marine fuel',
  'Cement manufacturing',
  'Chemicals',
  'Coal',
  'Electricity generation',
  'Metal manufacturing',
  'Mining',
  'Oil & Gas',
  'Steel',
  'Transport',
  'Water and waste products'
]

export default [
  {
    id: 1,
    fields: [
      {
        name: 'year',
        label: 'Year',
        placeholder: 2050,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value >= 2020 && value <= 2200 && Number.isInteger(Number(value))
            } else {
              return false
            }
          },
          text: 'Needs to be an integer greater than or equal to 2020 and smaller than or equal to 2200'
        }
      },
      {
        name: 'interim_target_year',
        label: 'Interim target year',
        placeholder: 2050,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value >= 2000 && value <= 2200 && Number.isInteger(Number(value))
            } else {
              return true
            }
          },
          text: 'Optional. Needs to be an integer greater than or equal to 2000 and smaller than or equal to 2200'
        }
      },
      {
        name: 'interim_baseline_year',
        label: 'Interim baseline year',
        placeholder: 1990,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 1900 && value < 2020 && Number.isInteger(Number(value))
            } else {
              return true
            }
          },
          text: 'Optional. Needs to be an integer greater than 1900 and smaller than 2020'
        }
      },
      {
        name: 'interim_target',
        label: 'Interim target (%)',
        placeholder: 50,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0 && value < 100 && Number.isInteger(Number(value))
            } else {
              return true
            }
          },
          text: 'Optional. Needs to be an integer greater than 0 and smaller than 100'
        }
      }
    ]
  },
  {
    id: 2,
    fields: [
      {
        name: 'tax_rate_local',
        label: 'Local tax rate',
        placeholder: 6.0,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0
            } else {
              return true
            }
          },
          text: 'Number greater than zero. Rate in local currency.'
        },
        formatter: function (data) {
          const value = data.tax_rate_local + ' ' + data.ccy_local + '/tCO2e'
          return value
        }
      },
      {
        name: 'ccy_local',
        label: 'Local currency',
        type: 'select',
        options: currencyOptions,
        skip: true
      },
      {
        name: 'tax_rate_usd',
        label: 'Normalised tax rate (USD, 31 Oct 2022)',
        help: 'Rate in USD as at 31 October 2022.',
        placeholder: 6.0,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0
            } else {
              return true
            }
          },
          text: 'Number greater than zero. Rate in USD as at 31 October 2022.'
        },
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = data.tax_rate_usd + 'USD/tCO2e'
          return value
        }
      },
      {
        name: 'tax_level',
        label: 'Tax level',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.lmh(data.tax_level)
          return value
        }
      },
      {
        name: 'scheduled_tax_increases',
        label: 'Scheduled tax increases',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'impact',
        label: 'Impact',
        type: 'select',
        options: [0, 33, 66, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.impact)
          return value
        }
      },
      {
        name: 'penalty',
        label: 'Penalty for non-compliance',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'exemptions',
        label: 'Exemptions availabe',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'coverage',
        label: 'Tax coverage',
        type: 'checkbox',
        options: taxCoverage
      }
    ]
  },
  {
    id: 3,
    fields: [
      {
        name: 'ets_type',
        label: 'Type',
        type: 'select',
        options: ['Cap-and-trade', 'Baseline-and-credit', 'Other'],
        chart: true,
        dataGetter: 'directObjectOptions'
      },
      {
        name: 'ets_mandatory',
        label: 'Mandatory',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'ets_rate_local',
        label: 'Local average price',
        placeholder: 6.0,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0
            } else {
              return true
            }
          },
          text: 'Number greater than zero. Rate in local currency.'
        },
        formatter: function (data) {
          const value = data.ets_rate_local + ' ' + data.ccy_local + '/tCO2e'
          return value
        }
      },
      {
        name: 'ccy_local',
        label: 'Local currency',
        type: 'select',
        options: currencyOptions,
        skip: true
      },
      {
        name: 'ets_rate_usd',
        label: 'Normalised average price (USD, 31 Oct 2022)',
        placeholder: 6.0,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0
            } else {
              return true
            }
          },
          text: 'Number greater than zero. Rate in USD as at 31 October 2022.'
        },
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = data.ets_rate_usd + 'USD/tCO2e'
          return value
        }
      },
      {
        name: 'price_level',
        label: 'Average price level',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.price_level)
          return value
        }
      },
      {
        name: 'penalty',
        label: 'Penalty for non-compliance',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'ets_declining_baseline',
        label: 'Legislated declining baseline',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'ets_allocation_method',
        label: 'Allocation method',
        type: 'checkbox',
        options: ['Free allocation', 'Auction', 'Private sale']
      },
      {
        name: 'impact',
        label: 'Impact',
        type: 'select',
        options: [0, 33, 66, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nlmh(data.impact)
          return value
        }
      }
    ]
  },
  {
    id: 4,
    fields: [
      {
        name: 'current_impact',
        label: 'Current impact',
        type: 'select',
        options: [0, 25, 50, 75, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.impact(data.current_impact)
          return value
        },
        validation: {
          method: function (value) {
            return true
          },
          text: '0, 25, 50, 75 or 100'
        }
      },
      {
        name: 'change_direction',
        label: 'Direction of change',
        type: 'select',
        options: [0, 25, 50, 75, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.impact(data.change_direction)
          return value
        }
      }
    ]
  },
  {
    id: 5,
    fields: [
      {
        name: 'rate_local',
        label: 'Indicative price',
        placeholder: 6.0,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0
            } else {
              return true
            }
          },
          text: 'Number greater than zero. Price in local currency.'
        },
        formatter: function (data) {
          const value = data.rate_local + ' ' + data.ccy + '/tCO2e'
          return value
        }
      },
      {
        name: 'price_level',
        label: 'Indicative price level',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.price_level)
          return value
        }
      },
      {
        name: 'ccy',
        label: 'Local currency',
        type: 'select',
        options: currencyOptions,
        skip: true
      },
      {
        name: 'rate_usd',
        label: 'Normalised price (USD, 31 Oct 2022)',
        placeholder: 6.0,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0
            } else {
              return true
            }
          },
          text: 'Number greater than zero. Price in USD as at 31 October 2022.'
        },
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = data.rate_usd + ' USD/tCO2e'
          return value
        }
      },
      {
        name: 'mandatory',
        label: 'Mandatory participation',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'penalty',
        label: 'Penalty for non-compliance',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'carbon_import_rate_type',
        label: 'Rate type',
        type: 'select',
        options: ['Fixed', 'Variable'],
        chart: true,
        dataGetter: 'directObjectOptions'
      },
      {
        name: 'carbon_import_linked_ets',
        label: 'Linked to ETS allowance price',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'carbon_import_declining_allowance',
        label: 'Declining amount of free allocations',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      }
    ]
  },
  {
    id: 8,
    fields: [
      {
        name: 'renewables',
        label: 'Renewables (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'biofuels',
        label: 'Renewable - Biofuels (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'waste',
        label: 'Renewable - Biomas (incl. wood) (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'geothermal',
        label: 'Renewable - Geothermal (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'hydro',
        label: 'Renewable - Hydro (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'renewable_other',
        label: 'Renewable - Other (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'solar_pv',
        label: 'Renewable - Solar PV (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'solar_thermal',
        label: 'Renewable - Solar Thermal (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'tide',
        label: 'Renewable - Tide (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'wind',
        label: 'Renewable - Wind (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'non_renewables',
        label: 'Non-renewables (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'coal',
        label: 'Non-renewable - Coal (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'natural_gas',
        label: 'Non-renewable - Natural gas (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'nuclear',
        label: 'Non-renewable - Nuclear (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'oil',
        label: 'Non-renewable - Oil (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'renewables_rating',
        label: 'Renewables rating',
        type: 'select',
        options: [0, 50, 100],
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.lmh(data.renewables_rating)
          return value
        }
      },
      {
        name: 'target_pc',
        label: 'Renewable electricity target (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value === '' || value === null || value === undefined) {
              return true
            } else if (value >= 0 && value <= 100) {
              return true
            } else {
              return false
            }
          },
          text: '0-100 or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'target',
        label: 'Renewable electricity target (GW)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number greater than or equal to zero, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'target_level',
        label: 'Renewables target level',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.target_level)
          return value
        }
      },
      {
        name: 'incentives',
        label: 'Incentive coverage',
        type: 'checkbox',
        options: ['R&D', 'Grants', 'Concessional loans & guarantees', 'Output / production-based incentives / subsidies', 'Public procurement targets']
      },
      {
        name: 'signal',
        label: 'Incentive investment signal',
        type: 'select',
        options: [0, 50, 100],
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.lmh(data.signal)
          return value
        }
      }
    ]
  },
  {
    id: 9,
    fields: [
      {
        name: 'demand',
        label: 'Existing domestic hydrogen demand (Mt)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number greater than or equal to zero, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'target',
        label: 'Hydrogen target (Mtpa, 2030)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number greater than or equal to zero, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'target_gw',
        label: 'Hydrogen target (GW, 2030)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number greater than or equal to zero, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'incentives',
        label: 'Incentive coverage',
        type: 'checkbox',
        options: ['R&D', 'Grants', 'Concessional loans & guarantees', 'Output / production-based incentives / subsidies', 'Public procurement targets']
      },
      {
        name: 'signal',
        label: 'Incentive investment signal',
        type: 'select',
        options: [0, 50, 100],
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nlmh(data.signal)
          return value
        }
      }
    ]
  },
  {
    id: 21,
    fields: [
      {
        name: 'current',
        label: 'Current battery and storage capacity (GW)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number greater than or equal to zero, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'grid_target',
        label: 'Grid storage capacity target (GWh)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number greater than or equal to zero, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'manufacturing_target',
        label: 'Manufacturing output target (GWh)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number greater than or equal to zero, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'grants',
        label: 'Incentive coverage',
        type: 'checkbox',
        options: ['R&D', 'Grants', 'Concessional loans & guarantees', 'Tax incentives', 'Output / production-based incentives / subsidies', 'Public procurement targets']
      },
      {
        name: 'signal',
        label: 'Incentive investment signal',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.lmh(data.signal)
          return value
        }
      }
    ]
  },
  {
    id: 22,
    fields: [
      {
        name: 'fuels',
        label: 'Fuel covered',
        type: 'checkbox',
        options: ['Gasoline', 'Diesel', 'Liquified Petroleum Gas (LPG)', 'Compressed Natural Gas (CNG)', 'Liquified Natural Gas (LNG)', 'Other natural gases', 'Biodiesel', 'Ethanol', 'Hydrogen', 'Aviation fuel', 'Jet fuel', 'Kerosene', 'Solvents', 'Lubricants', 'Gas oil']
      },
      {
        name: 'unit',
        label: 'Unit of measurement',
        type: 'select',
        options: ['Litre', 'Gallon'],
        default: 'Litre'
      },
      {
        name: 'rate',
        label: 'Gasoline tax rate (local currency per unit of measurement)',
        placeholder: 6.0,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0
            } else {
              return true
            }
          },
          text: 'Number greater than zero. Rate in local currency.'
        },
        formatter: function (data) {
          const value = data.rate + ' ' + data.ccy + '/' + data.unit
          return value
        }
      },
      {
        name: 'ccy',
        label: 'Local currency',
        type: 'select',
        options: currencyOptions,
        skip: true
      },
      {
        name: 'rate_usd',
        label: 'Gasoline tax rate (normalised USD/litre, 31 Oct 2022)',
        placeholder: 6.0,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0
            } else {
              return true
            }
          },
          text: 'Number greater than zero. Rate in USD/litre as at 31 October 2022.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'rate_relative',
        label: 'Gasoline tax rate level',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.lmh(data.rate_relative)
          return value
        }
      },
      {
        name: 'scheduled_increases',
        label: 'Scheduled increases',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean'
      },
      {
        name: 'point',
        label: 'Tax levied',
        type: 'checkbox',
        options: ['At import', 'At pump', 'At refinery']
      }
    ]
  },
  {
    id: 23,
    fields: [
      {
        name: 'national_strategy',
        label: 'National strategy',
        type: 'select',
        options: ['Yes', 'No', 'Under development'],
        default: 'Yes',
        validation: {
          method: function (value) {
            return true
          },
          text: 'Select one.'
        }
      },
      {
        name: 'standards_commercial',
        label: 'National energy efficiency standards for commercial buildings and construction',
        type: 'select',
        options: ['Yes', 'No', 'Under development'],
        default: 'Yes',
        validation: {
          method: function (value) {
            return true
          },
          text: 'Select one.'
        }
      },
      {
        name: 'standards_residential',
        label: 'National energy efficiency standards for residential buildings and construction',
        type: 'select',
        options: ['Yes', 'No', 'Under development'],
        default: 'Yes',
        validation: {
          method: function (value) {
            return true
          },
          text: 'Select one.'
        }
      },
      {
        name: 'standards_consumer',
        label: 'National energy efficiency standards for consumer products',
        type: 'select',
        options: ['Yes', 'No', 'Under development'],
        default: 'Yes',
        validation: {
          method: function (value) {
            return true
          },
          text: 'Select one.'
        }
      },
      {
        name: 'coverage',
        label: 'Incentive coverage',
        type: 'checkbox',
        options: ['R&D', 'Grants', 'Concessional loans & guarantees', 'Tax incentives', 'Output / production-based incentives / subsidies', 'Public procurement targets', 'Feed-in tariffs (on-site co-generation)', 'Fixed premiums (on-site co-generation)']
      },
      {
        name: 'signal',
        label: 'Incentive investment signal',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.lmh(data.signal)
          return value
        },
        validation: {
          method: function (value) {
            return true
          },
          text: 'Select one.'
        }
      }
    ]
  },
  {
    id: 24,
    fields: [
      {
        name: 'ev_sales',
        label: 'Proportion of EVs among overall vehicle sales in 2022 (%)',
        type: 'number',
        validation: {
          method: function (value) {
            if ((value >= 0 && value <= 100) || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number between 0 and 100, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'chargers',
        label: 'Number of public charging points',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number greater than or equal to zero, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'ev_per_charger',
        label: 'Number of EVs per public charging point',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Number greater than or equal to zero, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'strategy',
        label: 'EV strategy',
        type: 'select',
        options: ['Yes', 'No', 'Under development'],
        default: 'Yes'
      },
      {
        name: 'mandate',
        label: 'EV sales mandate for manufacturers',
        type: 'select',
        options: ['Yes', 'No'],
        default: 'Yes'
      },
      {
        name: 'phase_out',
        label: 'ICE phase out target',
        type: 'select',
        options: ['Yes', 'No'],
        default: 'Yes'
      },
      {
        name: 'phase_out_year',
        label: 'ICE phase out year',
        type: 'number',
        validation: {
          method: function (value) {
            if ((value >= 2020 && Number.isInteger(Number(value))) || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Integer greater than or equal to 2020, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'emissions_standard',
        label: 'National vehicle emissions standard',
        type: 'select',
        options: ['Yes', 'No'],
        default: 'Yes'
      },
      {
        name: 'fuel_standard',
        label: 'National fuel efficiency standard',
        type: 'select',
        options: ['Yes', 'No'],
        default: 'Yes'
      },
      {
        name: 'coverage',
        label: 'Incentive coverage',
        type: 'checkbox',
        options: ['R&D', 'Consumer purchase support', 'Charging infrastructure support', 'Public procurement targets']
      },
      {
        name: 'signal',
        label: 'Incentive investment signal',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.lmh(data.signal)
          return value
        }
      }
    ]
  },
  {
    id: 25,
    fields: [
      {
        name: 'measures',
        label: 'Domestic legislated maritime GHG emissions measures',
        type: 'select',
        options: ['Yes', 'No', 'Under development'],
        default: 'Yes',
        dataGetter: 'directObjectBoolean',
        chart: true
      },
      {
        name: 'marpol',
        label: 'Ratified MARPOL Annex VI',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        },
        dataGetter: 'directObjectBoolean',
        chart: true
      },
      {
        name: 'ets',
        label: 'Coverage of maritime transport in carbon pricing or trading schemes',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        },
        dataGetter: 'directObjectBoolean',
        chart: true
      }
    ]
  },
  {
    id: 26,
    fields: [
      {
        name: 'strategy',
        label: 'Sustainable aviation strategy',
        type: 'select',
        options: ['Yes', 'No', 'Under development'],
        default: 'Yes'
      },
      {
        name: 'net_zero',
        label: 'Net zero target for aviation',
        type: 'select',
        options: ['Yes', 'No'],
        default: 'Yes'
      },
      {
        name: 'net_zero_year',
        label: 'Net zero target year for aviation',
        type: 'number',
        validation: {
          method: function (value) {
            if ((value >= 2020 && Number.isInteger(Number(value))) || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Integer greater than or equal to 2020, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'offsets',
        label: 'Use of offsets in achieving net zero',
        type: 'select',
        options: ['Yes', 'No'],
        default: 'Yes'
      },
      {
        name: 'flights',
        label: 'Coverage of flights',
        type: 'checkbox',
        options: ['Domestic', 'International']
      },
      {
        name: 'fuel_target',
        label: 'Sustainable aviation fuel target',
        type: 'select',
        options: ['Yes', 'No'],
        default: 'Yes'
      },
      {
        name: 'coverage',
        label: 'Incentive coverage',
        type: 'checkbox',
        options: ['R&D', 'Grants', 'Concessional loans & guarantees', 'Tax incentives', 'Output / production-based incentives / subsidies', 'SAF-specific production-based subsidies', 'Public procurement targets']
      },
      {
        name: 'fuel_grant',
        label: 'Sustainable aviation fuel grant',
        type: 'select',
        options: ['Yes', 'No'],
        default: 'Yes'
      },
      {
        name: 'grant_level',
        label: 'Sustainable aviation fuel grant level',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.grant_level)
          return value
        }
      },
      {
        name: 'fuel_tax_credit',
        label: 'Sustainable aviation fuel tax credit',
        type: 'select',
        options: ['Yes', 'No'],
        default: 'Yes'
      },
      {
        name: 'fuel_tax_credit_level',
        label: 'Sustainable aviation fuel tax credit level',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.fuel_tax_credit_level)
          return value
        }
      }
    ]
  },
  {
    id: 27,
    fields: [
      {
        name: 'strategy',
        label: 'CCS strategy',
        type: 'select',
        options: ['Yes', 'No', 'Under development'],
        default: 'Yes'
      },
      {
        name: 'capacity',
        label: 'Estimated potential CO2 storage capacity (metric gigatonnes)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Integer greater than or equal to 0, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'capacity_level',
        label: 'Estimated potential CO2 storage capacity level',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.lmh(data.capacity_level)
          return value
        }
      },
      {
        name: 'target',
        label: 'Annual CO2 capture target by 2030 (million metric tones of CO2 per year)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Greater than or equal to 0, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'coverage',
        label: 'Incentive coverage',
        type: 'checkbox',
        options: ['R&D', 'Grants', 'Concessional loans & guarantees', 'Tax incentives', 'Output / production-based incentives / subsidies']
      },
      {
        name: 'signal',
        label: 'Investment signal',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.signal)
          return value
        }
      }
    ]
  },
  {
    id: 28,
    fields: [
      {
        name: 'target',
        label: 'Alternative fuel (non-hydrogen) production target',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'coverage',
        label: 'Incentive coverage',
        type: 'checkbox',
        options: ['R&D', 'Grants', 'Concessional loans & guarantees', 'Output / production-based incentives / subsidies', 'Public procurement targets']
      },
      {
        name: 'signal',
        label: 'Incentive investment signal',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.signal)
          return value
        }
      }
    ]
  },
  {
    id: 29,
    fields: [
      {
        name: 'consumer_goods',
        label: 'Embodied emissions target for consumer goods',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'buildings',
        label: 'Embodied emissions target for construction and buildings',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'protections',
        label: 'Greenwashing protections related to embodied emissions claims',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'coverage',
        label: 'Incentive coverage',
        type: 'checkbox',
        options: ['R&D', 'Grants', 'Concessional loans & guarantees', 'Tax incentives', 'Output / production-based incentives / subsidies', 'Public procurement targets']
      },
      {
        name: 'signal',
        label: 'Incentive investment signal',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.signal)
          return value
        }
      }
    ]
  },
  {
    id: 30,
    fields: [
      {
        name: 'gross_co2',
        label: 'Gross tCO2 1990-2020 (gross metric megatonnes since 1990)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Greater than or equal to 0, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'net_zero_target',
        label: 'Net zero target',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'baseline_year',
        label: 'Emissions baseline year',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Greater than or equal to 0, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'emissions_baseline_year',
        label: 'Total emissions in baseline year (millions of tonnes)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Greater than or equal to 0, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'target_2030',
        label: 'Emissions % reduction target by 2030 compared to baseline year',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Greater than or equal to 0, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'target_2050',
        label: 'Emissions % reduction target by 2050 compared to baseline year',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Greater than or equal to 0, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'intensity',
        label: 'Emissions intensity target (kg of CO2e per dollar GDP)',
        type: 'number',
        validation: {
          method: function (value) {
            if (value >= 0 || value === '' || value === null || value === undefined) {
              return true
            } else {
              return false
            }
          },
          text: 'Greater than or equal to 0, or empty.'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'offets',
        label: 'Are carbon offsets included in emissions abatement plan?',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'interim_45',
        label: 'Achieves 45% reduction by 2030',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'net_zero_2050',
        label: 'Achieves Net Zero by 2050',
        type: 'boolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'commitment',
        label: 'Strength of commitment',
        type: 'select',
        options: ['Not legislated', 'Legislated but not legally enforceable', 'Legally enforceable through penalties and regulation'],
        default: 'Not legislated'
      }
    ]
  },
  {
    id: 31,
    fields: [
      {
        name: 'regulations',
        label: 'Eco-design regulations in force',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'plastic',
        label: 'Single use plastic phase-out date set',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'signal',
        label: 'Incentive investment signal for circular product design and inputs',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.signal)
          return value
        }
      }
    ]
  },
  {
    id: 32,
    fields: [
      {
        name: 'regulations',
        label: 'Consumer protection regulation in place for circularity claims',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'labelling',
        label: 'Eco labelling scheme in place',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'verification',
        label: 'Third-party verification of circularity claims required',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      }
    ]
  },
  {
    id: 33,
    fields: [
      {
        name: 'repair',
        label: 'Repair standards for products',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'remanufacturing',
        label: 'Re-manufacturing inventives',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'target',
        label: 'National target for government circular procurement by 2030 (%)',
        placeholder: 50,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0 && value <= 100 && Number.isInteger(Number(value))
            } else {
              return true
            }
          },
          text: 'Optional. Needs to be an integer greater than 0 and smaller than or equal to 100'
        },
        chart: true,
        dataGetter: 'directObject'
      }
    ]
  },
  {
    id: 34,
    fields: [
      {
        name: 'strategy',
        label: 'National circularity strategy in place',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'coverage',
        label: 'Strategy coverage',
        type: 'checkbox',
        options: ['Product design procurement', 'Product marketing', 'Product use', 'End of use / end of life']
      }
    ]
  },
  {
    id: 35,
    fields: [
      {
        name: 'target',
        label: 'Target national resource recovery rate for all waste by 2030 (%)',
        placeholder: 50,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0 && value <= 100 && Number.isInteger(Number(value))
            } else {
              return true
            }
          },
          text: 'Optional. Needs to be an integer greater than 0 and smaller than or equal to 100'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'rate',
        label: 'Current national resource recovery rate (%)',
        placeholder: 50,
        type: 'number',
        validation: {
          method: function (value) {
            if (value) {
              return value > 0 && value <= 100 && Number.isInteger(Number(value))
            } else {
              return true
            }
          },
          text: 'Optional. Needs to be an integer greater than 0 and smaller than or equal to 100'
        },
        chart: true,
        dataGetter: 'directObject'
      },
      {
        name: 'scheme',
        label: 'National extended producer responsibility (EPR) scheme in place',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      }
    ]
  },
  {
    id: 36,
    fields: [
      {
        name: 'list',
        label: 'National critical materials list identified',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'coverage',
        label: 'Strategy coverage',
        type: 'checkbox',
        options: ['Aluminum (Al)', 'Antimony (Sb)', 'Arsenic (As)', 'Barite (BaSO4)', 'Beryllium (Be)', 'Bismuth (Bi)', 'Borates', 'Cerium (Ce)', 'Cesium (Cs)', 'Chromium (Cr)', 'Cobalt (Co)', 'Dysprosium (Dy)', 'Erbium (Er)', 'Europium (Eu)', 'Fluorspar (CaF2)', 'Gadolinium (Gd)', 'Gallium (Ga)', 'Germanium (Ge)', 'Graphite', 'Hafnium (Hf)', 'Heavy rare earth elements (HREEs)', 'Holmium (Ho)', 'Indium (In)', 'Iridium (Ir)', 'Lanthanum (La)', 'Lithium (Li)', 'Lutetium (Lu)', 'Magnesium (Mg)', 'Manganese (Mn)', 'Molybdenum (Mo)', 'Neodymium (Nd)', 'Nickel (Ni)', 'Niobium (Nb)', 'Palladium (Pd)', 'Platinum (Pt)', 'Praseodymium (Pr)', 'Rare earth elements (REEs)', 'Rhenium (Re)', 'Rhodium (Rh)', 'Ruthenium (Ru)', 'Samarium (Sm)', 'Scandium (Sc)', 'Silicon (Si)', 'Silicon Carbide (SiC)', 'Tantalum (Ta)', 'Tellurium (Te)', 'Terbium (Tb)', 'Thulium (Tm)', 'Titanium (Ti)', 'Tungsten (W)', 'Vanadium (V)', 'Ytterbium (Yb)', 'Yttrium (Y)', 'Zinc (Zn)', 'Zirconium (Zr)']
      },
      {
        name: 'target',
        label: 'National benchmark/target announced',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      },
      {
        name: 'priority',
        label: 'Priority products',
        type: 'checkbox',
        options: ['Bateries and battery components', 'Permanent magnets', 'Semiconductors (for micro-chips and solar PV)', 'High-performance alloys and metals (e.g. of magnesium, silicon, tungsten and titanium)', 'Phosphors (for high-efficiency lighting systems)']
      },
      {
        name: 'signal',
        label: 'Incentive investment signal for circular product design and inputs',
        type: 'select',
        options: [0, 50, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.nmh(data.signal)
          return value
        }
      },
      {
        name: 'mandatory',
        label: 'Mandatory requirements for companies to strenghten supply chain of critical materials',
        type: 'boolean',
        chart: true,
        dataGetter: 'directObjectBoolean',
        validation: {
          method: function (value) {
            return true
          },
          text: 'True or false'
        }
      }
    ]
  },
  {
    id: 37,
    fields: [
      {
        name: 'impact',
        label: 'Current impact',
        type: 'select',
        options: [0, 25, 50, 75, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.impact(data.impact)
          return value
        },
        validation: {
          method: function (value) {
            return true
          },
          text: '0, 25, 50, 75, 100'
        }
      },
      {
        name: 'change',
        label: 'Direction of change',
        type: 'select',
        options: [0, 25, 50, 75, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.impact(data.change)
          return value
        }
      }
    ]
  },
  {
    id: 38,
    fields: [
      {
        name: 'impact',
        label: 'Current impact',
        type: 'select',
        options: [0, 25, 50, 75, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.impact(data.impact)
          return value
        },
        validation: {
          method: function (value) {
            return true
          },
          text: '0, 25, 50, 75, 100'
        }
      },
      {
        name: 'change',
        label: 'Direction of change',
        type: 'select',
        options: [0, 25, 50, 75, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.impact(data.change)
          return value
        }
      }
    ]
  },
  {
    id: 39,
    fields: [
      {
        name: 'impact',
        label: 'Current impact',
        type: 'select',
        options: [0, 25, 50, 75, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.impact(data.impact)
          return value
        },
        validation: {
          method: function (value) {
            return true
          },
          text: '0, 25, 50, 75, 100'
        }
      },
      {
        name: 'change',
        label: 'Direction of change',
        type: 'select',
        options: [0, 25, 50, 75, 100],
        default: 0,
        chart: true,
        dataGetter: 'directObject',
        formatter: function (data) {
          const value = translators.impact(data.change)
          return value
        }
      }
    ]
  }
]
